<template>
  <div>
    <!-- <Headers :currentindex="1"/> -->
    <div class="banner">
      <h1 class="title">枫誉加拿大野生红刺参海参中的皇冠之一</h1>
      <div class="ctitle">
        <div class="icon">
          <img src="../assets/icon1.png" alt="">
          <p class="txt">北纬<span>51°</span>加拿大西海岸</p>
        </div>
        <div class="icon">
          <div class="txt">
            <p>纯净优越的<span>250</span>米海水下</p>
            <p>生长着一种稀少的野生红刺参</p>
          </div>
          <img src="../assets/icon2.png" alt="">
        </div>
        <div class="icon">
          <img src="../assets/icon3.png" alt="">
          <div class="txt">
            <p>它们常年深居在海底的沙石之下</p>
            <p>纯净环境让他们拥有无与伦比的</p>
            <span>鲜美肉质</span>
          </div>
        </div>
        <div class="icon">
          <div class="txt">
            <!-- <p class="te">拥有海参种类中</p>
            <p class="te">最为强劲的海参筋</p> -->
            <p class="te">拥有强劲的</p>
            <p class="te">海参筋</p>
          </div>
          <img src="../assets/icon4.png" alt="">
        </div>
      </div>
    </div>
    <div class="letter">
      <img src="../assets/letter.png" alt="">
    </div>
    <div class="honor">
      <div class="maincontent">
        <img class="logo1" src="../assets/logo1.png" alt="">
        <p class="logotip">
          <img src="../assets/logotip.png" alt="">
        </p>
        <div class="txt">
          <p>枫誉是一家总部位于加拿大温哥华的全球水产进出口企业。</p>
          <p>拥有限量发行的加拿大海参捕捞许可证，是老牌的加拿大水产出口企业。</p>
          <p>旗下拥有多艘经验丰富的合作捕捞渔船，是加拿大水产出口的重点企业。</p>
        </div>
        <div class="customer">
          <p>我们的客户</p>
          <img class="pccustomer" src="../assets/customer.png" alt="">
          <img class="mcustomer" src="../assets/mcustomer.png" alt="">
        </div>
      </div>
    </div>
    <Footers/>
  </div>
</template>
<script>
import Headers from './components/header'
import Footers from './components/footer'
export default {
  components: {
    Headers,
    Footers
  },
  data () {
   return {}
  },
  mounted() {},
  methods:{}
}
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 2787px;
  background: #000 url('./../assets/bg1.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  color: #fff;
  overflow: hidden;
  .title {
    width: 733px;
    margin:0 auto;
    font-size: 72px;
    margin-top: 239px;
    font-weight: 300;
    line-height: 78px;
    text-indent: 12px;
  }
  .ctitle {
    margin-top: 172px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: 32px;
      font-weight: 300;
    }
    span {
      font-weight: 300;
      font-size: 70px;
      margin:0 8px;
    }
    .te {
      font-weight: 500;
      font-size: 50px;
    }
    .icon:nth-child(1) {
      .txt {
        margin-left: 15px;
      }
    }
    .icon:nth-child(2) {
      margin-top: 137px;
      .txt {
        margin-right: 17px;
      }
    }
    .icon:nth-child(3) {
      margin-top: 149px;
      .txt {
        margin-left: 38px;
      }
    }
    .icon:nth-child(4) {
      margin-top: 185px;
      .txt {
        margin-right: 29px;
      }
    }
  }
}
.letter {
  width: 100%;
  height: 1581px;
  background: #000 url('./../assets/bg12.png') no-repeat center center;
  background-size: cover;
  position: relative;
  img {
    width: 1397px;
    height: auto;
    position: absolute;
    top: -72px;
    left:0;
    right:0;
    margin:0 auto;
  }
}
.honor {
  background: #FFFEFB;
  text-align: center;
  overflow: hidden;
  .logo1 {
    width: 201px;
    height: 239px;
    margin-top: 182px;
  }
  .logotip {
    font-size: 29px;
    margin-top: 34px;
    color: #D71718;
  }
  .txt {
    font-size: 16px;
    color: #603E2D;
    margin-top: 56px;
    p {
      margin-top: 20px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 115px;
    li {
      margin:0 15px;
      img {
        width: 334px;
        height: auto;
      }
    }
  }
  .customer {
    margin-top: 183px;
    .pccustomer {
      display: block;
    }
    .mcustomer {
      display: none;
    }
    p {
      font-size: 28px;
      color: #603E2D;
    }
    img {
      margin: 36px auto 193px;
    }
  }
}
@media screen and(max-width: 1414px){
  .letter {
    width: 100%;
    height: 1100px;
    background: #000 url('./../assets/bg12.png') no-repeat center center;
    background-size: cover;
    position: relative;
    img {
      width: 960px;
      height: auto;
      position: absolute;
      top: -40px;
      left:0;
      right:0;
      margin:0 auto;
    }
  }
}
@media screen and(max-width: 768px){
  .banner {
    height: 1887px;
    background: #000 url('./../assets/bg13.png') no-repeat center center;
    background-size: 100% 100%;
    .title {
      width: 320px;
      font-size: 30px;
      margin-top: 120px;
      line-height: 39px;
    }
    .ctitle {
      margin-top: 160px;
      .icon {
        flex-wrap: wrap!important;
        margin-top: 65px !important;
        .txt {
          width: 100%;
          margin-top: 10px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 300;
        margin-top: 6px;
      }
      span {
        font-weight: 300;
        font-size: 24px;
        margin:0 8px;
      }
      .te {
        font-weight: 500;
        font-size: 20px;
      }
      img {
        width: 168px;
        height: 168px;
      }
      .icon:nth-child(1) {
        .txt {
          margin-left: 0;
        }
      }
      .icon:nth-child(2) {
        flex-direction: column-reverse;
        .txt {
          margin-right: 0;
        }
      }
      .icon:nth-child(3) {
        .txt {
          margin-left: 0;
        }
      }
      .icon:nth-child(4) {
        flex-direction: column-reverse;
        .txt {
          margin-right: 0;
        }
      }
    }
  }
  .letter {
    width: 100%;
    height: 1286px;
    background:#fff url('./../assets/bg14.png') no-repeat center center;
    background-size: 100% 1327px;
    position: relative;
    img {
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left:0;
      right:0;
      margin:0 auto;
    }
  }
  .honor {
    background: #fff;
    .logo1 {
      width: 73px;
      height: 90px;
      margin-top: 57px;
    }
    .logotip {
      font-size: 12px;
      margin-top: 17px;
      img {
        width: 108px;
      }
    }
    .txt {
      font-size: 12px;
      margin-top: 40px;
      padding:0 24px;
      p {
        margin-top: 20px;
      }
      p:nth-child(2) {
        width: 220px;
        margin:20px auto 0;
      }
      p:nth-child(3) {
        width: 220px;
        margin:20px auto 0;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      li {
        margin-bottom: 20px;
        img {
          width: 243px;
        }
      }
    }
    .customer {
      margin-top: 100px;
      .pccustomer {
        display: none;
      }
      .mcustomer {
        display: block;
      }
      p {
        font-size: 20px;
        color: #603E2D;
      }
      img {
        margin: 60px auto 100px;
      }
    }
  }
}
</style>